const exam_json = gon.js_exam_parts;

import { stopRecording, stopPrevRecording, startNewRecording } from '../packs/init-web-audio-recorder';
import { folder_create } from './audio_uploader';

function NormalVideoPlayer() {
    $("#TestStart").modal({
        backdrop: "static",
        keyboard: false,
    });

    const videoPlayer = document.getElementById("exam_player_video");
    const videoSource = document.getElementById("video_source");
    const playButton = document.getElementById("start_button");
    const nextPartButton = document.getElementById("next_part_button");
    const zoomButton = document.getElementById("zoom_button");
    const updateStatusButton = document.getElementById("update_status");
    const examPartTitle = document.getElementById("part-end-modal-title");
    const examPartDescription = document.getElementById("part-end-modal-body");

    let currentPartIndex = 0;
    let recordingStarted = false;
    let nextVideoPlayer;

    window.folder_id = false;
    var videoPlaying = false;
    var firstIndex = true;
    var completed = false;

    const loadVideoPart = async (index) => {
        if (index < Object.keys(exam_json).length) {
            const currentPart = exam_json[index];
            videoSource.src = currentPart[0];
            videoPlayer.load();
            examPartTitle.innerHTML = currentPart[1];
            examPartDescription.innerHTML = currentPart[2];
            await videoPlayerLoaded(); // Ensure video is loaded before proceeding
            if (!completed && videoPlaying) {
                videoPlayer.play().catch(error => {
                    console.log("Error playing video:", error);
                });
            }
            preloadNextVideoPart(index + 1);
        }
    };

    const preloadNextVideoPart = (index) => {
        if (index < Object.keys(exam_json).length) {
            const nextPart = exam_json[index];
            if (!nextVideoPlayer) {
                nextVideoPlayer = document.createElement('video');
            }
            nextVideoPlayer.src = nextPart[0];
            nextVideoPlayer.load();
        }
    };

    const videoPlayerLoaded = () => {
        return new Promise((resolve) => {
            videoPlayer.onloadeddata = () => {
                resolve();
            };
        });
    };

    const checkRecordingStart = () => {
        const duration = videoPlayer.duration;
        if (duration < 40) {
            return;
        }
        const currentTime = videoPlayer.currentTime;
        if (duration - currentTime < 128 && !recordingStarted) {
            console.log("RECORDING STARTED");
            startNewRecording();
            recordingStarted = true;
        }
    };

    videoPlayer.addEventListener("loadedmetadata", () => {
        console.log("Video has loaded");
        if (firstIndex) {
            firstIndex = false;
        } else {
            if (!completed && videoPlaying) {
                videoPlayer.play().catch(error => {
                    console.log("Error playing video:", error);
                });
            }
        }
    });

    videoPlayer.addEventListener("timeupdate", checkRecordingStart);

    videoPlayer.addEventListener("play", () => {
        console.log("Video has started playing");
    });

    videoPlayer.addEventListener("ended", async () => {
        console.log("Video has ended");
        currentPartIndex++;
        console.log(currentPartIndex, Object.keys(exam_json).length, "LENGTH AND INDEX");
        if (currentPartIndex < Object.keys(exam_json).length) {
            stopPrevRecording();
            recordingStarted = false;
            await loadVideoPart(currentPartIndex); // Ensure video is loaded before proceeding
        } else {
            completed = true;
            videoPlayer.pause();
            stopRecording();
            console.log("The exam is finished");
            $("#upload_completed_part").text(`0 / ${Object.keys(exam_json).length}`);
            $("#EndModal-Loader").modal({
                backdrop: "static",
                keyboard: false,
            });
        }
    });

    videoPlayer.addEventListener("pause", () => {
        if (!completed && videoPlaying) {
            videoPlayer.play().catch(error => {
                console.log("Error playing video:", error);
            });
        }
    });

    playButton.addEventListener("click", async () => {

        const not_detected = () => {
            alert("No microphone detected");
            setTimeout(() => {
                $("#TestStart").modal({
                    backdrop: "static",
                    keyboard: false,
                });
            }, 1000);
            document.getElementById("start_button").disabled = true;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            if(!stream){
                not_detected();
                return;
            }
        } catch (error) {
            not_detected();
            return;
        }

        window.test_started = true;
        try {
            if(window.test_media_recorder){
                if(typeof window.test_media_recorder?.stop == "function"){
                    window.test_media_recorder?.stop()
                }
                if(typeof window.test_audio_player?.play == "function"){
                    window.test_audio_player?.pause()
                    URL.revokeObjectURL(window.test_audio_player_url);
                }
            }
            // if (window.test_audioContext) {
            //     window.test_audioContext.close();
            //     window.test_microphone = null;
            //     window.test_audioDestination = null;
            // }
        } catch (error) {
            console.log(error)
        }
        videoPlaying = true;
        videoPlayer.play().then(() => {
            videoPlayer.volume = 1;
            folder_create((data) => {
                console.log("FOLDER CREATED", data);
            });
        }).catch(error => {
            console.log("Error playing video:", error);
        });
    });

    nextPartButton.addEventListener("click", () => {
        // videoPlayer.play();
        // stopPrevRecording();
    });

    zoomButton.addEventListener("click", () => {
        document.querySelector(".video-card").classList.toggle("video-card-zoom");
        zoomButton.innerText = zoomButton.innerText === "拡大 / Maximize" ? "縮小 / Minimize" : "拡大 / Maximize";
    });

    updateStatusButton.addEventListener("click", () => {
        $("#EndModal-1").modal("hide");
        $("#EndModal-2").modal({
            backdrop: "static",
            keyboard: false,
        });
    });

    // Initial load
    loadVideoPart(currentPartIndex);
}

export default NormalVideoPlayer;
